@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.wx-time-picker {
  ::v-deep input[type="time"] {
    margin-top: 13px; // contrain height to match default WxTextField

    // html5 native clock icon
    &::-webkit-calendar-picker-indicator {
      filter: invert(60%); // = opacity: 0.6
    }
  }
  // legacy : Hide html5 icon if we show a v-icon
  &.prepend-icon {
    ::v-deep input[type="time"]::-webkit-calendar-picker-indicator {
      visibility: hidden;
    }
  }
}

@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

//This even weirder CSS is to apply 'text-overflow: ellipsis' to the tag name but not the duration, while working with a fluid width. To work with the fluid width, we need to calculate the max-width of the v-chip/tag to force a constraint so the ellipsis effect work. This is done with media queries and CSS variables.

.tag-ellipsis {
  display: flex;
  align-items: center;
  justify-content: start;
  //for xs default for 2 items per row */
  max-width: calc((100vw - (2 * var(--grid-gutter)) - (2 * var(--box-padding-dashboard) * 2) - (12px * 2)) / 2);

  span:first-child {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-grow: 1;
    min-width: 0;
  }

  span:last-child {
    flex-shrink: 0;
  }
}

/*Evaluate the pu-item/v-card width based on number of grid,breakpoint but also  grid gap, padding from puItem, padding from the view, width of navbar( not in xs/sm)
 ex: (100vw - (3 * var(--grid-gutter)) - (3 * var('puitem--padding') * 2) - ('padding--of--overView--view' * 2)) - ('navBar-width') / 3);*/
@media ($wx-sm-min) {
  .tag-ellipsis {
    max-width: calc((100vw - (3 * var(--grid-gutter)) - (3 * var(--box-padding-dashboard) * 2) - (20px * 2)) / 3);
  }
}

@media ($wx-md-min) {
  .tag-ellipsis {
    max-width: calc(
      (100vw - (4 * var(--grid-gutter)) - (4 * var(--box-padding-dashboard) * 2) - (24px * 2) - 56px) / 4
    );
  }
}

@media ($wx-lg-min) {
  .tag-ellipsis {
    max-width: calc(
      (100vw - (5 * var(--grid-gutter)) - (5 * var(--box-padding-dashboard) * 2) - (32px * 2) - 56px) / 5
    );
  }
}

@media ($wx-xl-min) {
  .tag-ellipsis {
    max-width: calc(
      (100vw - (6 * var(--grid-gutter)) - (6 * var(--box-padding-dashboard) * 2) - (40px * 2) - 56px) / 6
    );
  }
}

@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.quantity-graph-container {
  $containerDefaultHeight: 40px;

  flex: 1 0 auto;
  position: relative;
  overflow: hidden;
  height: ($containerDefaultHeight * 2);
  margin-top: 10px;

  @media ($wx-isNotMobile) {
    height: $containerDefaultHeight;
  }

  .canvas-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;

    ::v-deep canvas {
      height: 100%;
      width: 100%;
    }
  }
}

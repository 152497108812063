@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.timeline-tics-container {
  flex: 0 0 auto;
  position: relative;
  height: 24px;
  margin-top: 8px;

  .tic {
    position: absolute;
    top: 0;
    height: 3px;
    width: 1px;
    background-color: currentColor;

    &.tic-info {
      height: 6px;
    }

    > .tic-number {
      position: absolute;
      top: 9px;
      left: 1px;
      transform: translateX(-50%);
    }

    > .tic-date {
      top: 25px;
      position: absolute;
      white-space: nowrap;
    }
  }
}

@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

::v-deep .v-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-base-theme);

  &__content {
    display: flex;
    align-items: center;
    flex-flow: column nowrap;
    position: initial;
    left: auto;
    width: 100%;
    max-width: 600px;
    padding-inline: var(--grid-gutter);
    pointer-events: auto;

    header {
      padding-top: var(--grid-gutter);

      .close-btn {
        position: fixed;
        z-index: 1; // hover fields
        top: var(--dialog-close-offset);
        right: var(--dialog-close-offset);
      }
    }
    .v-form {
      width: 100%;

      fieldset {
        border: none;

        &.form-footer-actions {
          display: flex;
          justify-content: flex-end;
          column-gap: var(--btn-inline-margin);
          padding-bottom: var(--grid-gutter);
        }
      }

      // Responsive Columns
      .row {
        margin-top: 0;
        margin-bottom: 0;
        .field-col {
          padding-top: 0;
          padding-bottom: 0;
        }
      }
    }
  }
}

@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.date-picker-window {
  width: 100%;
  min-width: 320px;
  overflow-y: hidden;

  // over-rule vuetify
  ::v-deep .v-card,
  ::v-deep .v-list,
  ::v-deep .v-picker__body {
    background-color: transparent !important;
  }

  .centered {
    margin: auto;
  }

  .close-window-btn {
    position: absolute;
    z-index: 1;
    top: calc(var(--dialog-close-offset) / 3);
    right: calc(var(--dialog-close-offset) / 3);
    cursor: pointer;
  }

  .timezone-info {
    color: var(--color-text-subtle-theme);
  }
}

.d-flex > .flex-mobile-phone-row {
  align-self: flex-end;
}

.calendar-picker {
  background-color: var(--color-primary) !important;
}

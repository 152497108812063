@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.wx-panel.v-card {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: var(--border-radius-lg); // Tiles border-radius
  padding-bottom: 35px;
}

.panel-header {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;

  .panel-title {
    margin-right: auto;

    ::v-deep .v-btn {
      &.product-selected,
      &.no-product-selected {
        .v-btn__content {
          @media (max-width: $wx-max-width-phone-vertical) {
            max-width: 130px;
          }
        }
      }
      &.product-selected {
        .v-btn__content {
          @media (min-width: $wx-min-width-phone-vertical) {
            max-width: 300px;
          }
          @media (min-width: $wx-min-width-tablet-vertical) {
            max-width: 420px;
          }
          @media ($wx-md-min) {
            max-width: 735px;
          }
        }
      }
    }
  }

  // Single buttons
  ::v-deep > .v-btn {
    &.legend-btn {
      margin-left: 4px;

      @media (min-width: $wx-min-width-tablet-vertical) {
        margin-left: var(--btn-inline-margin);
      }
    }
  }

  .toggle {
    > :first-child {
      border-radius: var(--border-radius-form-elements) 0 0 var(--border-radius-form-elements);
    }
    > :last-child {
      border-radius: 0 var(--border-radius-form-elements) var(--border-radius-form-elements) 0;
    }

    // when active UX = disable
    .v-btn.v-btn--active {
      pointer-events: none;
      cursor: auto;
    }
  }
}
.truncated {
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 200px;
}

.mobile-squeezed-btn {
  ::v-deep &.v-btn {
    flex-grow: 1;
    flex-shrink: 1;
    @media (max-width: $wx-breakpoint-phone-vertical) {
      padding-inline: 5px !important;
    }
  }
}

.graphOptionMenu {
  width: 150px;
}
.selectedGraphIcon {
  color: inherit;
  float: right;
}

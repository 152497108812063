@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.wx-modal-dialog {
  &.v-card {
    &.v-sheet {
      position: relative;
      max-height: 90vh;
      max-height: 90svh;
      padding: var(--dialog-padding);
      background-color: var(--color-element-layer3);
    }
  }

  // BEM
  .v-card {
    &__title {
      position: sticky;
      z-index: 1;
      top: 0;
      padding-top: 0;
      padding-bottom: var(--dialog-padding);
      background-color: var(--color-element-layer3);
    }
    &__text {
      position: relative;
      z-index: 0;
    }
  }
}

@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.wx-panel.v-card {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: var(--border-radius-lg); // Tiles border-radius
  padding-bottom: 35px;
}

.panel-header {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;

  .panel-title {
    margin-right: auto;

    .label {
      font-weight: 400; /* Regular */
      margin-right: 0.15em;

      &::after {
        content: ":";
      }
      &:lang(fr)::after {
        padding-left: 0.2em;
      }
    }
  }

  // Single buttons
  ::v-deep > .product-btn,
  ::v-deep > .legend-btn {
    margin-left: var(--btn-inline-margin);
  }
}

.v-item-group.v-btn-toggle {
  & > .v-btn {
    width: 50%;

    // fix a vuetify glitch where after 2nd click an active button looks darker.
    opacity: 1;
    &.v-btn--active {
      &:hover::before,
      &::before {
        opacity: 0;
      }
    }
    // when active color is primary and UX = disable
  }
}

@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.target-label-graph {
  position: absolute;
  padding: 5px;
  border-radius: var(--border-radius-form-elements);
  background-color: var(--color-base-background);
  text-align: center;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 6px;
  z-index: 1;
}

.giveaway-graph-container {
  flex: 1 0 auto;
  position: relative;
  overflow: hidden;
  margin-top: 10px;

  .canvas-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;

    ::v-deep canvas {
      height: 100%;
      width: 100%;
    }
  }
  .x-axe-lines {
    position: absolute;
    left: 0;
    right: 0;
    border-top: 1px solid currentColor;

    &.target-line {
      color: var(--color-text-theme);
      padding-right: 4px;
      text-align: right;
    }
    &.treshold-line {
      color: var(--color-error);
      border-top-width: 2px;
      border-top-style: dashed;
      padding-right: 4px;
      text-align: right;

      &.maximum {
        top: 0;
      }
      &.minimum {
        bottom: 0;
      }
    }
  }
}

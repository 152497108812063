@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';


// local variables
$timeframeRow_height: 40px;
$datepickerActivator_singleDate_minWidth: 7em; // Adjust if we display more then `DD Mmm.` in the field
$datepickerActivator_2datesRange_minWidth: ($datepickerActivator_singleDate_minWidth + 8.5em);

// local extended class
.extend_focusedBackgroundPattern {
  background-image: var(--pattern-upcoming-time-sm-theme);
  border-radius: 3px;
  cursor: pointer;
}

/**
 * Form filters
 * common style
 */
.filter-form {
  fieldset.filters {
    display: flex;
    flex-basis: content;
    flex-grow: 0;
    margin: 0;
    padding: 0;
    border: none;

    // style
    ::v-deep .v-input.v-input--dense {
      .v-input__control {
        .v-input__slot {
          border-radius: var(--border-radius-form-elements);

          // hide border-bottom
          &::before,
          &::after {
            display: none;
            border-color: transparent !important;
          }
        }
      }
    }
  }
}

// v-card
.date-picker-window {
  overflow-y: hidden;

  .close-window-btn {
    position: absolute;
    z-index: 1;
    top: calc(var(--dialog-close-offset) / 3);
    right: calc(var(--dialog-close-offset) / 3);
    cursor: pointer;
  }

  // fix black background in darkMode
  ::v-deep .v-card.v-picker {
    &,
    .v-picker__body {
      background-color: transparent;
    }
  }
}

// Main responsive columns
.datepicker-col {
  &--first-column,
  &--optional-column-container {
    display: flex;
  }
  &--first-column,
  &--optional-column-container {
    ::v-deep &.col {
      margin-top: 0;
      padding-top: 0;
    }
  }
  &--optional-column-container {
    padding-bottom: 0;
  }
  &--first-column {
    align-items: stretch;
    flex-direction: column; // mobile first

    @media ($wx-isMobilePhone) {
      padding-bottom: 24px;
    }
    @media ($wx-sm-min) {
      flex-direction: row;
    }
  }
}

.timeframe {
  &--main,
  &--aside {
    align-items: center;
    justify-content: space-between;

    @media ($wx-sm-min) {
      justify-content: flex-start;
    }
  }
  // .timeframe--main
  &--main {
    flex-wrap: nowrap;
    text-align: left;

    @media ($wx-isMobilePhone) {
      flex-wrap: wrap;
    }
  }
  // .timeframe--aside
  &--aside {
    height: $timeframeRow_height;
    margin-top: -0.15rem;

    @media ($wx-isMobile) {
      padding-inline: calc(var(--grid-gutter) / 2);
    }
  }

  // .timeframe--main__selector
  &--main__selector {
    max-width: 13rem;
  }

  //  .timeframe--main__date-buttons
  &--main__date-buttons {
    &.v-btn-toggle {
      min-width: 100px;

      .v-btn.v-btn {
        &.chevron {
          min-width: 48px;
          padding-inline: 0;
        }
        // more square then the default rectangular ratio
        &.v-size--default {
          height: $timeframeRow_height;
        }

        // hacking Vuetify `plain` style to match mockup
        &.v-btn--plain {
          background-color: transparent;
          transition: var(--smooth-transition);
          transition-property: background-color;

          &:hover {
            background-color: var(--color-secondary);
          }
          ::v-deep .v-btn__content {
            opacity: 1;
          }
        }
      }
    }
  }

  @media ($wx-isMobile) {
    &--main__selector {
      max-width: 60%;
    }
    // Vertical mobilePhone ONLY
    @media ($wx-isMobilePhone) {
      &--main__selector {
        max-width: 100%;
        margin-inline: auto;
        margin-bottom: 12px;
      }
      &--main__date-buttons {
        &.v-btn-toggle {
          max-width: 100%;
          margin-inline: auto;

          .v-btn.v-btn {
            &.chevron {
              min-width: 60px;
            }
            &.text {
              min-width: 12em;
            }
          }
        }
      }
    }
  }

  // .timeframe--aside__shift-name
  &--aside__shift-name {
    display: inline-block;
    margin-bottom: 0;
    font-size: var(--font-size-norm);

    &.cursor-help {
      cursor: help;
    }
  }

  // .timeframe--aside__datepicker-activator
  &--aside__datepicker-activator {
    // Default date format is `30 Sep.`
    &:not(.dates-range) {
      min-width: $datepickerActivator_singleDate_minWidth;
      max-width: 10rem;
    }
    // If workShift is spread over more then one day: `30 Sep. - 1 Oct.`
    &.dates-range {
      min-width: $datepickerActivator_2datesRange_minWidth;
      max-width: 16rem;
    }

    @media ($wx-isMobilePhone) {
      margin-inline: auto;
    }

    ::v-deep .v-label {
      font-size: var(--font-size-norm);
      color: var(--color-text-theme);
    }
    &:hover {
      ::v-deep .v-label {
        color: var(--color-primary);
      }
    }
    ::v-deep .v-input__control {
      .v-input__append-inner {
        margin-top: 1px; // vertically align with carret from first select
      }
    }
    // ugly patch until I fix the UX
    &.v-input {
      &--is-focused ::v-deep input {
        @extend .extend_focusedBackgroundPattern;
      }
    }
    &.v-select {
      &--is-focused ::v-deep .v-select__selections {
        @extend .extend_focusedBackgroundPattern;
      }
    }
  }
}

/**
 * ui/FormFooterActions.vue
 */
.form-footer-actions {
  border-color: transparent;
  border-radius: 0;
}

@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.wx-confirmation-dialog-card {
  &.v-card {
    position: relative;
    max-width: var(--modal-window-medium-width);
    padding: var(--dialog-padding);
    background-color: var(--color-element-layer2);
    border-radius: var(--border-radius-lg);
    opacity: 1;

    ::v-deep .v-icon {
      font-size: var(--font-size-h1);
    }
    .v-card__text {
      font-size: var(--font-size-norm);
    }
    .heading,
    p {
      text-align: center;
    }

    &,
    .v-icon,
    .v-card__text,
    .v-card__actions,
    .v-expansion-panel-content {
      color: var(--color-text-theme);
    }

    .v-card {
      // Card Header
      &__title {
        flex-direction: column;
        justify-content: center;
        word-break: break-word;

        p {
          margin-bottom: 0;
        }
      }
    }

    // if expansionPanel is enable
    .v-expansion-panel {
      max-width: var(--modal-window-narrow-width);

      &::before {
        box-shadow: none;
        display: none;
      }

      ::v-deep .v-expansion-panel-header {
        flex-direction: column;
        justify-content: center;
        padding-top: 0;
        padding-bottom: 1em;
        border-bottom: 1px solid var(--color-border-theme);

        &__icon {
          margin-left: 0;

          .v-icon {
            color: var(--color-text-theme);
            border-radius: 50%;
            background-color: var(--color-border-theme);
          }
        }
      }
      .v-expansion-panel-content {
        padding-top: 1em;
        opacity: 0.9;

        p {
          text-align: left;
        }
      }
    }
  }
}

.step-list {
  list-style-type: decimal;
  padding-left: 1.7rem;

  li {
    margin-bottom: var(--dialog-small-padding);
  }
}

@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

// local var
$caretSize: 8px;

// style
.v-application {
  .marker-dialog {
    transform: translateY(-25%);
    z-index: 2;

    &--right {
      margin-left: (($caretSize * 2.5) - 1px);
    }
  }
}

.timeline-marker-card {
  &.v-card {
    min-width: 13em;
    background-color: var(--color-element-layer1);
    color: var(--color-text-theme);
    opacity: 1;

    .v-card {
      &__title {
        justify-content: space-between;

        .v-btn-toggle {
          max-width: 30%;
          .nav-btn {
            max-width: 50%;
          }
        }
        .heading {
          width: 70%;
          font-weight: 700;
          text-align: center;
        }
      }
      &__text {
        color: var(--color-text-theme);

        .data-report {
          display: flex;
          column-gap: 0.5em;
          margin-bottom: 0.5em;
          font-size: var(--font-size-sm);

          .label,
          .icon-as-label,
          .data {
            line-height: 1.35;
          }
          .label,
          .data {
            color: currentcolor;
          }

          .label,
          .icon-as-label {
            width: 5em;
            text-align: left;
            margin-right: 0;
          }
          .label {
            word-break: break-word;
            opacity: 0.8;

            &:not(.icon-as-label):after {
              content: " :";
            }
          }
          .icon-as-label {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            padding-right: 0.25rem;
            opacity: 0.7;

            .v-icon {
              font-size: 1.15em;
              color: currentcolor;
            }
          }
          .data {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            width: calc(100% - 5em);

            time {
              text-align: right;
            }
          }
        }
      }
    }
  }
}

.marker-btn {
  &.v-btn {
    position: absolute;
    z-index: 1;
    height: auto;
    min-width: 0;
    top: ($caretSize - 5px);
    padding: 2px;
    background-color: transparent;
    transform: translateX(-50%);
    transition: transform 0.3s ease;
    border-radius: 0;

    ::v-deep .v-btn__content {
      opacity: 1 !important;
    }
    &:hover,
    &:focus,
    &--active {
      z-index: 3;
      padding-top: 1px;
      border-top: 2px solid var(--color-primary);
    }
  }
  // triangle shape
  &__caret {
    display: block;
    width: 0;
    height: 0;
    border-top: $caretSize solid var(--color-inverted-container-theme);
    border-right: $caretSize solid transparent;
    border-left: $caretSize solid transparent;
    transition: border-color 0.2s linear;

    @at-root .marker-btn--active #{&} {
      border-top-color: var(--color-primary);
    }
  }
}

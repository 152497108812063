@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.timeline-blocks-container {
  $containerDefaultHeight: 40px;

  position: relative;
  flex: 1 0 auto;
  height: ($containerDefaultHeight * 2);
  background-image: var(--repeating-oblique-small-pattern);

  @media ($wx-isNotMobile) {
    height: $containerDefaultHeight;
    background-image: var(--repeating-oblique-pattern);
  }
}

// timeline.blocks template
.block {
  position: absolute;
  height: 100%;
  top: 0;
  width: 10px;
  transition: background-color 0.3s linear;

  &:focus {
    outline: none;
  }

  &.selected {
    z-index: 1;
    outline: 2px dashed var(--color-warning);
  }

  &.up {
    background-color: var(--color-uptime);
  }
  &.down_unjustified,
  .down_unjustified {
    background-color: var(--color-unjustifiedDowntime);
  }
  &.down_unplanned,
  .down_unplanned {
    background-color: var(--color-justifiedDowntime);
  }
  &.down_planned,
  .down_planned {
    background-color: var(--color-plannedDowntime);
  }
  &.out_of_production {
    background-color: var(--color-outOfProduction);
  }
  &.unknown {
    background-color: var(--color-unknown);
  }
  &.connection_issues {
    background-color: var(--color-disconnected);
  }
  &.down_mixed {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: flex-start;
  }
  &.hidden {
    background-color: var(--color-timeline-graph-background);
  }
}

// shiftMarkers template
.shift-marker {
  position: absolute;
  top: 0;
  width: 1px;
  height: 100%;
  z-index: 1;
  left: 50%;
  background-color: currentColor;

  &:after,
  &:before {
    content: "";
    position: absolute;
    width: 11px;
    height: 2px;
    left: -5px;
    top: 25%;
    background-color: currentColor;
  }

  &:after {
    transform: rotate(45deg);
  }

  &:before {
    transform: rotate(-45deg);
  }
}

.progress-marker {
  position: absolute;
  top: -6px;
  width: 3px;
  height: calc(100% + 17px);
  background-color: currentColor;
}

.v-tooltip__content {
  background-color: var(--color-inverted-container-theme);
}

.wx-info-window {
  max-width: 220px;
  color: var(--color-inverted-text-theme);
  background-color: var(--color-inverted-container-theme);

  .text {
    opacity: 0.9;
  }
}

.defaultMouseCursor {
  cursor: default;
}

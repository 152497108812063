@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

// Extended style
.extend_activeRootItem {
  color: var(--color-text-theme);
  background-color: var(--color-control-contrast-theme);
}

// Styles
.activator-btn {
  &.v-btn {
    position: absolute;
    z-index: 1;
    top: var(--box-padding-dashboard);
    right: var(--box-padding-dashboard);
    min-width: 0 !important;
    padding: 0 !important;
  }
}

.tile-selector {
  &.v-card {
    background-color: var(--color-element-layer3);
    border-radius: 0;
  }
}

.accordion-list {
  &.v-list {
    .v-icon {
      color: inherit;
    }
    // BEM
    .accordion-list {
      &--row {
        // mdi-lock and mdi-check icons
        &.inline-status-icon {
          display: flex;
          justify-content: space-between;

          .v-icon {
            margin-right: 3px; // vertically aligned with chevrons
          }
        }
      }
      /**
       * Orphan Single Items
       */
      &__single-list {
        &__item {
          &.v-list-item {
            &.v-item--active {
              @extend .extend_activeRootItem;
            }
          }
        }
      }
      /**
       * Toggleable Grouped Items
       */
      &__toggleable-group {
        ::v-deep .v-list-group__header {
          &.v-list-item--active {
            @extend .extend_activeRootItem;
          }
        }
        &__list {
          box-shadow: var(--box-shadow-small-elevation);

          &__item {
            // padding-left: 2em; // indentating is NOT on mockup

            &.v-list-item {
              &:not(.v-item--active):not(.v-list-item--disabled) {
                color: var(--color-text-subtle-theme);
              }
              &.v-item--active {
                color: var(--color-text-theme); // over-rule Vuetify primary
              }
            }
          }
        }
      }
    }
  }
}

@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.oee-graph-container {
  $containerDefaultHeight: 40px;

  flex: 1 0 auto;
  position: relative;
  overflow: hidden;
  height: ($containerDefaultHeight * 2);
  margin-top: 10px;
  background-color: var(--color-control-background-theme);

  @media ($wx-isNotMobile) {
    height: $containerDefaultHeight;
  }

  .canvas-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;

    ::v-deep canvas {
      height: 100%;
      width: 100%;
    }
  }
  .x-axe-target-line {
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    padding-right: 4px;
    text-align: right;
    color: var(--color-error);
    border-top: 2px dashed currentColor;
  }
}
